import { useCallback } from "react";
import { useDispatch, connect } from "react-redux";
import { CLEAR_ALL } from "../oauth/util/actionTypes";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Templates from "../views/Templates";
import CustomerView from "../views/CustomerView";
import Customers from "../views/Customers";
import CustomerAdd from "../views/CustomerAdd";

import Auth from "../components/auth/Auth";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import LoginError from "../components/auth/LoginError";

import AuthVerify from "../components/auth/AuthVerify";

const Router = ({ profile }) => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch({ type: CLEAR_ALL });
    window.location = "/login";
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>

        <Route
          exact
          element={
            <ProtectedRoute profile={profile} restrict_to_group="CloudOps" />
          }
        >
          <Route exact path="/" element={<Customers />} />
          <Route exact path="/customers" element={<Customers />} />
          <Route exact path="/customers/add" element={<CustomerAdd />} />
          <Route exact path="/customers/:customer_code/view" element={<CustomerView />} />
          <Route exact path="/templates" element={<Templates />} />
        </Route>


        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/login" element={<Login />} />
        <Route exect path="/auth" element={<Auth />} />
        <Route exect path="/login-error" element={<LoginError />} />
      </Routes>

      <AuthVerify logout={logout} />
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(Router);
