import { connect } from "react-redux";
import { SpaceBetween, Container } from "@cloudscape-design/components";

import ReburaAppLayout from "../../layout/ReburaAppLayout";

const Forbidden = () => {
	return (
		<ReburaAppLayout page_title='Forbidden'>
			<SpaceBetween size='l'>
				<Container>
					You dont have the correct permissions to see this stuff
				</Container>
			</SpaceBetween>
		</ReburaAppLayout>
	);
};

export default connect()(Forbidden);
