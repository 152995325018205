import { connect } from "react-redux";
import { SpaceBetween } from "@cloudscape-design/components";

import ReburaAppLayout from "../layout/ReburaAppLayout";
import Customer from "../components/customers/Customer";

const CustomerView = () => {
	return (
		<ReburaAppLayout page_title=''>
			<SpaceBetween size='l'>
				<Customer />
			</SpaceBetween>
		</ReburaAppLayout>
	);
};

export default connect()(CustomerView);
