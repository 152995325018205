import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Container,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";

import useFetchData from '../../hooks/useFetchData';

import StackConfigDisplay from "../cloudformation/StackConfigDisplay";
import CloudformationStackList from "../cloudformation/CloudformationStackList";
import CustomerDetailsSection from "./CustomerDetailsSection";
import { useLayout } from '../../layout/LayoutContext';

import KeycloakLoginButton from "../common/KeycloakLoginButton"


const Customer = ({token}) => {

	const {
	    setFlashMessages,
	    setSplitPanelOpen,
	    setSplitPanel,
	} = useLayout();

	const { customer_code } = useParams();
	const [refreshKey, setRefreshKey] = useState(0);
  const { data, loading, error } = useFetchData(token, `customers/${customer_code}`, 'GET', refreshKey, setFlashMessages);

	const [activeTabId, setActiveTabId] = useState(
		() => localStorage.getItem('activeTabId') || 'stacker_config'
  );

  const handleTabChange = (event) => {
    const newTabId = event.detail.activeTabId;

    if (newTabId === "stack_config") {
    	setSplitPanel()
    	setSplitPanelOpen(false)
    }

    setActiveTabId(newTabId);
    localStorage.setItem('activeTabId', newTabId); // Save the active tab ID in localStorage
  };

  if (loading) {
    return "Loading";
  }

  if (error) {
    return "";
  }

  const customer = data['customer']

	return (
			<SpaceBetween size="l">
		        <Container header={<Header variant="h1"
							actions={
								<KeycloakLoginButton customer={customer} />
		        	}>
		        	{customer.customer_name}
		        	</Header>}>
		        	<hr />
		        	<br />
		        	<CustomerDetailsSection customer={customer} />
		        </Container>

		        <Container>
		          <Tabs
		            activeTabId={activeTabId}
		            onChange={handleTabChange}
		            tabs={[
		              {
		                label: "Stack Configuration",
		                id: "stack_config",
		                content: (
		                  <StackConfigDisplay
		                    customer={customer}
		                    setRefreshKey={setRefreshKey}
		                  />
		                )
		              },
		              {
		                label: "Cloudformation Stacks",
		                id: "cloudformation_stacks",
		                content: (
		                  <CloudformationStackList
		                    customer={customer}
		                  />
		                )
		              }
		            ]}
		          />
		        </Container>

		    </SpaceBetween>
	);
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(Customer);
