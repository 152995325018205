import {
  Box
} from "@cloudscape-design/components";

const EmptyState = ({ title, subtitle, action }) => (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );

export default EmptyState