import { useState } from "react";
import {
  Header,
  SplitPanel,
  Container,
  Tabs
} from "@cloudscape-design/components";

import { useLayout } from '../../layout/LayoutContext';

import SplitViewParameters from './SplitViewParameters';
import SplitViewStackEvents from './SplitViewStackEvents';

const CloudformationStackSplitViewContent = ({ selectedStack, selectedRegion, customer }) => {

    const {
      setSplitPanelOpen,
      setSplitPanel,
  } = useLayout();


  const [activeTabId, setActiveTabId] = useState(
    () => localStorage.getItem('stackSplitViewSelectedTabId') || 'parameters'
  );

  const handleTabChange = (event) => {
    const newTabId = event.detail.activeTabId;

    if (newTabId === "stack_config") {
      setSplitPanel()
      setSplitPanelOpen(false)
    }

    setActiveTabId(newTabId);
    localStorage.setItem('stackSplitViewSelectedTabId', newTabId); // Save the active tab ID in localStorage
  };



  return (

    <SplitPanel
        header={<Header>Stack Details: {selectedStack.StackName}</Header>}
        hidePreferencesButton={true}
        onDismiss={() => setSplitPanelOpen(false)}
      >


      <Container>
        <Tabs
          activeTabId={activeTabId}
          onChange={handleTabChange}
          tabs={[
            {
              label: "Parameters",
              id: "parameters",
              content: (
                <SplitViewParameters selectedStack={selectedStack} />
              )
            },
            {
              label: "Events",
              id: "events",
              content: (
                <SplitViewStackEvents selectedRegion={selectedRegion} selectedStack={selectedStack} customer={customer} />
              )
            }
          ]}
        />
      </Container>

    </SplitPanel>
    )

}

export default (CloudformationStackSplitViewContent)
