import {
  Box,
  Header
} from "@cloudscape-design/components";

const SplitViewParameters = ({ selectedStack }) => {


    if (selectedStack.StackStatus === "UPDATE_REQUIRED") {

      const { parameters_diff: { new: newParameters, delete: deletedParameters, change: changedParameters } = {} } = selectedStack || {};

      return(
        <Box>

          {/* New Parameters */}
          {newParameters && Object.keys(newParameters).length > 0 ? (
            <section>
              <h3>New Parameters</h3>
                <ul>
                  {newParameters && Object.entries(newParameters).map(([key, value]) => (
                    <li key={key} style={{ marginBottom: '4px', borderRadius: '4px' }}>
                      <div >
                        <strong>{key}:</strong>
                        <br />
                        <span style={{ color: 'green', backgroundColor: '#e6ffed', padding: '4px', borderRadius: '4px' }}>{value}</span>
                      </div>
                    </li>
                  ))}
                </ul>
            </section>
          ) : (null)}

          {/* Deleted Parameters */}
          {deletedParameters && Object.keys(deletedParameters).length > 0 ? (
            <section>
              <h3>Deleted Parameters</h3>
                <ul>
                  {deletedParameters && Object.entries(deletedParameters).map(([key, value]) => (
                    <li key={key} style={{ marginBottom: '4px', borderRadius: '4px' }}>
                      <div >
                        <strong>{key}:</strong>
                        <br />
                        <span style={{ color: 'red', backgroundColor: '#ffeef0', padding: '4px', borderRadius: '4px' }}>{value}</span>
                      </div>
                    </li>
                  ))}
                </ul>
            </section>
          ) : (null)}

          {/* Changed Parameters */}
          {changedParameters && Object.keys(changedParameters).length > 0 ? (
            <section>
              <h3>Changed Parameters</h3>
                <ul>
                  {changedParameters && Object.entries(changedParameters).map(([key, [oldValue, newValue]]) => (
                    <li key={key} style={{ marginBottom: '4px', borderRadius: '4px' }}>
                      <div >
                        <strong>{key}:</strong>
                        <br />
                        <span style={{ color: 'red', backgroundColor: '#ffeef0', padding: '4px', borderRadius: '4px' }}>{oldValue}</span>
                        <br />
                        <span style={{ color: 'green', backgroundColor: '#e6ffed', padding: '4px', borderRadius: '4px' }}>{newValue}</span>
                      </div>

                    </li>
                  ))}
                </ul>
            </section>
          ) : (null)}
        </Box>)

    } else {

      return (
          <Box padding={{ vertical: 'm', horizontal: 'l' }}>
            <Header variant="h3">Parameters</Header>
            {selectedStack.Parameters && selectedStack.Parameters.length > 0 ? (
              <ul>
                {selectedStack.Parameters.map((parameter, index) => (
                  <li key={index}>
                    <strong>{parameter.ParameterKey}:</strong> {parameter.ParameterValue}
                  </li>
                ))}
              </ul>
            ) : (
              <Box>No parameters available</Box>
            )}
          </Box>)
    }

}

export default (SplitViewParameters)