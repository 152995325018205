import { useState } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
  Button,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './CustomerListTableConfig';

import EmptyState from '../common/EmptyState'
import useFetchData from '../../hooks/useFetchData';

import CustomerListTableHeader from './CustomerListTableHeader'

const CustomersList = ({ token, title }) => {

  const [refreshKey] = useState(0);
  const { data, loading, error } = useFetchData(token, 'customers', 'GET', refreshKey);

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'customer_code', visible: true },
      { id: 'customer_name', visible: true },
      { id: 'aws_account_id', visible: true },
      { id: 'modified_on', visible: true },
      // { id: 'stacker_status', visible: true },
      // { id: 'latest_stacker_run', visible: true },
      { id: 'keycloak_access', visible: true },
      { id: 'observability_access', visible: true },
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    data['customers'] || [],
    {
      filtering: {
        empty: <EmptyState title="No Customers" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[3], isDescending: true } },
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <Table
        {...collectionProps}
        selectionType="multi"
        header={
          <CustomerListTableHeader selectedItems={selectedItems} items={data['customers']} />
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading customers"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter customers"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomersList);
