
import {
    Button
 } from "@cloudscape-design/components";

const KeycloakLoginButton = ({ customer }) => {

    const transformString = input => input.replace(/[&() ]/g, match => ({ '&': '-and-', '(': '_', ')': '_', ' ': '-' }[match]));

    const keycloakAccessStack = customer.stacks.find(
      (stack) => stack.TemplateName === "keycloak-access"
    );

    if (keycloakAccessStack) {

        const namespaceParameter = keycloakAccessStack.Parameters.find(
          (param) => param.ParameterKey === "CustomerNamespace"
        );

        const namespace = transformString(namespaceParameter.ParameterValue)

        return (

            <Button
              ariaLabel="AWS Console Login (opens new tab)"
              href={`https://keycloak-prod.tooling.rebura.cloud/realms/rebura-aws/protocol/saml/clients/${namespace}`}
              iconAlign="right"
              iconName="external"
              target="_blank"
            >
              AWS Console
            </Button>
        )

    } else {
        return null
    }



}

export default KeycloakLoginButton