import { useState, useCallback } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
  Button,
  SpaceBetween,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
} from "@cloudscape-design/components";
import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './TemplatesListTableConfig';
import AddTemplateModal from "./AddTemplateModal";

import useFetchData from '../../hooks/useFetchData';

import EmptyState from '../common/EmptyState'
import TemplatesHelpPanel from './TemplatesHelpPanel'
import TemplatesTableHeader from './TemplatesTableHeader'

import { useLayout } from '../../layout/LayoutContext';

const TemplatesList = ({ token }) => {

  const {
      setFlashMessages
  } = useLayout();

  const [refreshKey, setRefreshKey] = useState(0);
  const { data, loading, error } = useFetchData(token, 'templates', 'GET', refreshKey, setFlashMessages);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'name', visible: true },
      { id: 'template_path', visible: true },
      { id: 'region', visible: true },
      { id: 'notification_arns', visible: true },
      { id: 'parameters', visible: true },
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    data['templates'] || [],
    {
      filtering: {
        empty: <EmptyState title="No templates" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0], isDescending: false } },
      selection: {},
    }
  );

  const handleModalSuccess = useCallback(() => {
    setIsModalVisible(false);
    setRefreshKey(prev => prev + 1);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <SpaceBetween size="xs">
      <AddTemplateModal
        token={token}
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        onSuccess={handleModalSuccess}
        // setParentFlashMessages={setFlashMessages}
      />

      <TemplatesHelpPanel />

      <Table
        {...collectionProps}
        selectionType={false}
        header={
          <TemplatesTableHeader
            items={data['templates']}
            setIsModalVisible={setIsModalVisible}
           />
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading templates"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter templates"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
    </SpaceBetween>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(TemplatesList);
