// hooks/useFetchData.js
import { useState, useEffect } from 'react';

import InvalidApiRequestError from "../errors/InvalidApiRequestError";

import config from "../config";

const useFetchData = (token, url, method, refresh, setFlashMessages=() => {}, postData={}) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
  fetch(`${config.api_endpoint}/${url}`, {
    mode: "cors",
    method: method,
    headers: {
      "content-type": "application/json",
      "x-authorization": `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new InvalidApiRequestError(response); // This throws the error directly
      }
    })
    .then((json) => {
      setData(json);
      setError(null);
    })
    .catch((error) => {
      if (error instanceof InvalidApiRequestError) {
        setError(error)
        error.response.json().then((json) => {
          setFlashMessages([
            {
              header: "Error",
              type: "error",
              content: json.Error || "Unknown error occurred",
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => setFlashMessages([]),
              id: "api_error",
            },
          ]);
        });
      } else {
        console.error(error);
        setError(error.message)
        setFlashMessages([
            {
              header: "Error",
              type: "error",
              content: error.message || "Unknown error occurred",
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => setFlashMessages([]),
              id: "api_error",
            },
          ]);
      }
    })
    .finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
}, [url, refresh, loading, method, token]);


  return { data, loading, error };
};

export default useFetchData;
