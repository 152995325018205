import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';

import {
    SideNavigation,
    AppLayout,
    TopNavigation,
    Flashbar
} from "@cloudscape-design/components";

import styled, { keyframes } from 'styled-components';

import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";
import { LayoutProvider, useLayout } from './LayoutContext'; // Import the context provider and hook
import { applyMode, applyDensity } from "@cloudscape-design/global-styles"; // Import theme utilities

const LOCALE = "en";


// Define the gradient animation
const moveGradient = keyframes`
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledTopNavigation = styled(TopNavigation)`
  border-bottom: 4px solid transparent;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #f16321, #9e297c, #f16321);
    background-size: 200% 200%; /* Ensures seamless loop */
    border-radius: inherit;
    animation: ${moveGradient} 5s linear infinite; /* Animation with speed */
  }
`;

const ReburaAppLayout = (props) => {
    const [themeMode, setThemeMode] = useState(() => localStorage.getItem('themeMode') || 'dark');
    const [densityMode, setDensityMode] = useState(() => localStorage.getItem('densityMode') || 'compact');

    // Apply themes (e.g., dark mode or compact mode)
    useEffect(() => {
        applyMode(themeMode);
        applyDensity(densityMode);
        localStorage.setItem('themeMode', themeMode);
        localStorage.setItem('densityMode', densityMode);
    }, [themeMode, densityMode]);

    props = {
        ...{
            show_breadcrumbs: true,
            nav_open: true,
            show_side_nav: true,
            page_title: "Rebura Stacker Portal",
        },
        ...props,
    };

    let navItems = [
        { type: "link", text: `Customers`, href: `/customers` },
        { type: "link", text: `Cloudformation Templates`, href: `/templates` },
    ];

    let util_list = [
        {
            type: "button",
            text: themeMode === 'dark' ? 'Light' : 'Dark',
            onClick: () => setThemeMode(prevMode => prevMode === 'dark' ? 'light' : 'dark'),
        },
        {
            type: "button",
            text: densityMode === 'compact' ? 'Comfortable' : 'Compact',
            onClick: () => setDensityMode(prevMode => prevMode === 'compact' ? 'comfortable' : 'compact'),
        }
    ];

    if (props.profile !== null) {
        util_list.push({
            type: "menu-dropdown",
            text: props.profile.fullname,
            description: props.profile.email,
            iconName: "user-profile",
            items: [{ type: "link", text: "Sign out", href: "/logout" }],
        });
    }

    return (
        <LayoutProvider>
            <I18nProvider locale={LOCALE} messages={[messages]}>
                <StyledTopNavigation
                    identity={{
                        href: "#",
                        title: "Customer Portal",
                        logo: {
                          src: "/logo.png",
                          alt: "rebura.",
                        }
                    }}
                    utilities={util_list}
                />

                <LayoutConsumerAppLayout props={props} navItems={navItems} />
            </I18nProvider>
        </LayoutProvider>
    );
};

const LayoutConsumerAppLayout = ({ props, navItems }) => {
    const {
        navOpen,
        setNavOpen,
        splitPanelOpen,
        setSplitPanelOpen,
        splitPanel,
        flashMessages
    } = useLayout();

    const navToggle = (e) => {
        setNavOpen(!navOpen);
    };

    return (
        <AppLayout
            title={props.page_title}
            toolsHide={true}
            onNavigationChange={navToggle}
            maxContentWidth={Number.MAX_VALUE}
            navigationOpen={navOpen}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
            splitPanel={splitPanel}
            navigation={
                props.show_side_nav ? (
                    <SideNavigation
                        header={{
                            href: "#",
                            text: "Navigation",
                        }}
                        items={navItems}
                    />
                ) : null
            }
            notifications={
              <Flashbar
                items={flashMessages}
              />
            }
            content={props.children}
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps)(ReburaAppLayout);
