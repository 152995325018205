import { useState } from "react";
import {
  SpaceBetween,
  Button,
  Form,
  FormField,
  Input,
  Container,
  Multiselect,
  Alert,
  Link,
  Box,
  Select,
  Header,
  ColumnLayout
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useFetchData from '../../hooks/useFetchData';
import { useLayout } from '../../layout/LayoutContext';
import InvalidApiRequestError from "../../errors/InvalidApiRequestError";

const CustomerAdd = ({ token }) => {

    const {
        setFlashMessages
    } = useLayout();


  const [refreshKey] = useState(0);
  const { data: templatesData, loading, error } = useFetchData(token, 'templates', 'GET', refreshKey, setFlashMessages);

  const [state, setState] = useState({
    selectedTemplates: [],
    parameters: {},
    customer_name: "",
    customer_code: "",
    aws_account_id: ""
  });
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
    setValidationErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    if (!state.customer_name) {
      errors.customer_name = "Customer Name is required.";
    }
    if (!state.customer_code) {
      errors.customer_code = "Customer Code is required.";
    }
    if (!state.aws_account_id || !/^[0-9]{12}$/.test(state.aws_account_id)) {
      errors.aws_account_id = "AWS Account ID must be a 12-digit number.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    fetch(`${config.api_endpoint}/customers`, {
      mode: "cors",
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`
      },
      body: JSON.stringify(state),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new InvalidApiRequestError(response); // This throws the error directly
      }
    })
    .then((json) => {
      navigate(`/customers/${json.customer.customer_code}/view`);
    })
    .catch((error) => {
      if (error instanceof InvalidApiRequestError) {
        error.response.json().then((json) => {
          setFlashMessages([
            {
              header: "Error",
              type: "error",
              content: json.Error || "Unknown error occurred",
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => setFlashMessages([]),
              id: "api_error",
            },
          ]);
        });
      } else {
        console.error(error);
      }
    })
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const templateUrl = "https://download-rebura-bucket.s3.eu-west-1.amazonaws.com/stacker-execution-role-template.yaml";

  const hiddenVars = ["OneLoginApp", "OneLoginSamlProvider", "ParentRole"];

  return (
    <Container header={<Header variant="h1">Add New Customer</Header>}>
      <SpaceBetween size="l">
        <Alert statusIconAriaLabel="Info" header="Have you deployed the role yet?">
          Before onboarding a customer to the Stacker pipeline, please ensure the stacker deployment role has been deployed in the target AWS account.
          <br />
          <br />
          Stacker is hard-coded to use the IAM role `rebura-deploy-StackerExecutionRole` and is included in the following CloudFormation template. The stack requires no parameters to be set so it is a simple deployment.
          <br />
          <br />
          <Link external href={templateUrl}>{templateUrl}</Link>
        </Alert>

        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button variant="primary">Submit</Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <ColumnLayout columns={2} variant="text-grid">
                <FormField
                  label="Customer Name"
                  description="The full name of the customer."
                  errorText={validationErrors.customer_name}
                >
                  <Input
                    placeholder="Enter customer name"
                    value={state.customer_name}
                    onChange={(e) => handleInputChange("customer_name", e.detail.value)}
                  />
                </FormField>

                <FormField
                  label="Customer Code"
                  description="A unique code to identify the customer."
                  errorText={validationErrors.customer_code}
                >
                  <Input
                    placeholder="Enter customer code"
                    value={state.customer_code}
                    onChange={(e) => handleInputChange("customer_code", e.detail.value)}
                  />
                </FormField>
              </ColumnLayout>

              <FormField
                label="AWS Account ID"
                description="The AWS Account ID for the customer."
                errorText={validationErrors.aws_account_id}
              >
                <Input
                  placeholder="Enter AWS Account ID"
                  value={state.aws_account_id}
                  onChange={(e) => handleInputChange("aws_account_id", e.detail.value)}
                />
              </FormField>

              <FormField label="Templates to Deploy" description="Select the templates to create stacks that you want to deploy for the customer.">
                <Multiselect
                  selectedOptions={state.selectedTemplates}
                  onChange={({ detail }) => handleInputChange("selectedTemplates", detail.selectedOptions)}
                  options={templatesData['templates'].map((template) => ({
                    label: template.name,
                    value: template.name,
                    ...template
                  }))}
                  placeholder="Choose templates"
                />
              </FormField>

              {state.selectedTemplates.map((template, templateKey) => {
                  return template.parameters.filter((param) => !hiddenVars.includes(param.name)).length > 0 && (
                    <Box key={`template-${templateKey}-${templateKey}`} margin={{ vertical: "m" }}>
                      <Box margin={{ bottom: "s" }}>
                        <b>Parameters for template: {template.name}</b>
                      </Box>

                      <ColumnLayout columns={2} variant="text-grid">
                        {template.parameters.map((parameter, paramKey) => {

                          if (hiddenVars.includes(parameter.name)) {
                            return null;
                          }

                          return (
                            <FormField
                              label={parameter.name}
                              key={`param-${templateKey}-${templateKey}-${paramKey}`}
                              description={parameter.description || "Enter the value for this parameter."}
                            >
                              {parameter.type === "string" && (
                                <Input
                                  placeholder={`Enter value for ${parameter.name}`}
                                  value={parameter.value}
                                  onChange={(e) => {
                                    const templates = state.selectedTemplates
                                    templates[templateKey].parameters[paramKey].value = e.detail.value;
                                    setState({ ...state, selectedTemplates: templates });
                                  }}
                                />
                              )}

                              {(parameter.type === "enum" || parameter.type === "boolean") && (
                                <Select
                                  selectedOption={{
                                    label: parameter.value,
                                    value: parameter.value
                                  }}
                                  onChange={({ detail }) => {
                                    const templates = state.selectedTemplates
                                    templates[templateKey].parameters[paramKey].value = detail.selectedOption.value;
                                    setState({ ...state, selectedTemplates: templates });
                                  }}
                                  options={parameter.valid_values.split(",").map((value) => ({
                                    label: value,
                                    value: value
                                  }))}
                                  placeholder={`Select value for ${parameter.name}`}
                                />
                              )}
                            </FormField>
                          );
                        })}
                      </ColumnLayout>
                    </Box>
                    )
              })}
            </SpaceBetween>
          </Form>
        </form>
      </SpaceBetween>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomerAdd);
