import { connect } from "react-redux";

import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";

import { getStackStatus } from "../cloudformation/CloudformationStackListTableConfig"

const CustomerDetailsSection = ({token, customer}) => {

    const statusCounts = {}

    customer.stacks.forEach(stack => {
      const status = stack.StackStatus;
      if (statusCounts[status]) {
        statusCounts[status]++;
      } else {
        statusCounts[status] = 1;
      }
    });

    return (

        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "AWS Account ID",
              value: customer.aws_account_id,
              // info: (
              //   <Link variant="info" href="#">
              //     Info
              //   </Link>
              // )
            },
            {
              label: "Customer Code",
              value: customer.customer_code
            },
            {
              label: "Last Modified By",
              value: customer.modified_by
            },
            {
              label: `Stack Status (${customer.stacks.length})`,
              value: Object.entries(statusCounts).map(([key, value]) => (
                <>
                {getStackStatus(key,`${key}: ${value}`)}
                <br />
                </>
              ))
            }
          ]}
        />
    )
}


const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomerDetailsSection);
