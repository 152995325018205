import React from 'react';
import { Outlet } from 'react-router-dom';

import Forbidden from '../components/auth/Forbidden'

const ProtectedRoute = ({profile, restrict_to_group}) => {

    if (profile && profile.groups.includes(restrict_to_group)) {
        return (<Outlet />)
    } else {
        return(
            <Forbidden />
        )
    }

}

export default ProtectedRoute