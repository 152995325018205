
import {
    Header,
    Button,
    SpaceBetween,
    Icon
 } from "@cloudscape-design/components";


const TemplatesTableHeader = ({items, setIsModalVisible}) => {

    console.log(items)

    return (
        <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setIsModalVisible(true)}>
                  Register New Template <Icon name='add-plus' />
                </Button>
              </SpaceBetween>
            }
          >
            Templates
          </Header>

    );
};


export default TemplatesTableHeader;


