import { useState } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
  Header,
  Button,
  Table,
  CollectionPreferences,
  Pagination
} from "@cloudscape-design/components";

import useFetchData from '../../hooks/useFetchData';
import { useLayout } from '../../layout/LayoutContext';
import EmptyState from '../common/EmptyState'

import { columnDefinitions, paginationLabels, collectionPreferencesProps } from './StackEventsTableConfig';


const SplitViewStackEvents = ({ token, customer, selectedRegion, selectedStack }) => {

  const {
      setFlashMessages,
  } = useLayout();

  const [refreshKey] = useState(0);

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    contentDisplay: [
      { id: 'Timestamp', visible: true },
      { id: 'LogicalId', visible: true },
      { id: 'Status', visible: true },
      { id: 'DetailedStatus', visible: false },
      { id: 'StatusReason', visible: true }
    ],
  });

  const { data, loading, error } = useFetchData(token, `customers/${customer.customer_code}/cloudformation/stacks/${encodeURIComponent(selectedStack['StackName'])}/${selectedRegion.value}/events`, 'GET', refreshKey, setFlashMessages);

  let { items, actions, collectionProps, paginationProps } = useCollection(
    data || [],
    {
      filtering: {
        empty: <EmptyState title="No events found" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[1], isDescending: false } },
      selection: {},
    }
  );

  if (error) {
    return "";
  }

  return (

    <Table
        {...collectionProps}
        header={
          <Header
            counter={`(${(data || []).length})`}
          >
            Stack Events
          </Header>
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading events"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />

    )

}

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(SplitViewStackEvents);
