import { useState } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
  Button,
  Header,
  SpaceBetween,
  ButtonDropdown,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
  Select
} from "@cloudscape-design/components";

import useFetchData from '../../hooks/useFetchData';

import EmptyState from '../common/EmptyState'
import CloudformationStackSplitViewContent from "./CloudformationStackSplitViewContent"
import ConfirmationPopup from "../common/ConfirmationPopup"
import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './CloudformationStackListTableConfig';
import { useLayout } from '../../layout/LayoutContext';
import config from "../../config";

const CloudformationStackList = ({ token, customer }) => {
  const {
      setSplitPanelOpen,
      setSplitPanel,
      setFlashMessages
  } = useLayout();

  const [refreshKey, setRefreshKey] = useState(0);

  const [selectedRegion, setSelectedRegion] = useState({
    label: "eu-west-1",
    value: "eu-west-1"
  });

  const _columnDefinitions = columnDefinitions(customer, setRefreshKey, selectedRegion, setFlashMessages)

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    contentDisplay: [
      { id: 'StackId', visible: false },
      { id: 'StackName', visible: true },
      { id: 'StackStatus', visible: true },
      { id: 'CreationTime', visible: true },
      { id: 'LastUpdatedTime', visible: false },
      { id: 'DeletionTime', visible: false },
      { id: 'Description', visible: true },
      { id: 'Actions', visible: true }
    ],
  });
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  const { data: regionData, loading: regionDataLoading, error: regionDataError } = useFetchData(token, `customers/${customer.customer_code}/cloudformation/regions`, 'GET', refreshKey, setFlashMessages);
  const { data: stackData, loading: stackDataLoading, error: stackDataError } = useFetchData(token, `customers/${customer.customer_code}/cloudformation/stacks/${selectedRegion.value}`, 'GET', refreshKey, setFlashMessages);

  let { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    stackData['stacks'] || [],
    {
      filtering: {
        empty: <EmptyState title="No stacks found" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: _columnDefinitions[1], isDescending: false } },
      selection: {},
    }
  );

  const { selectedItems, onSelectionChange } = collectionProps;

  const handleDropdownClick = async (e) => {
    e.preventDefault();

    try {
      switch (e.detail.id) {
        case 'delete':
          setConfirmDeleteModalVisible(true)
          break;
        case 'modify':
          // navigate(`/customers/${selectedItems[0]['customer_code']}/modify`);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDelete = async (e) => {
    // Handle the confirm action here
    console.log('Confirmed!');
    setConfirmDeleteModalVisible(false);

    fetch(`${config.api_endpoint}/customers/${customer.customer_code}/cloudformation/stacks/${encodeURIComponent(selectedItems[0]['StackName'])}`, {
        mode: "cors",
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ customers: selectedItems }),
      });
  };

  const handleCancelDelete = () => {
    setConfirmDeleteModalVisible(false);
  };

  const setSelectedStack = (e) => {
    const selectedStack = e['detail']['selectedItems'][0]
    setSplitPanel(<CloudformationStackSplitViewContent selectedRegion={selectedRegion} selectedStack={selectedStack} customer={customer} />)
    setSplitPanelOpen(true)
    onSelectionChange(e)
  }

  const handleRegionChange = ({ detail }) => {
    setSelectedRegion(detail.selectedOption);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  // if (stackDataLoading || regionDataLoading) {
  //   return <p>Loading...</p>;
  // }

  if (stackDataError || regionDataError) {
    return ""
  }

  const regions = regionData['regions'] || []

  return (
      <Table
        {...collectionProps}
        selectionType="single"
        header={
          <Header
            counter={`(${(stackData['stacks'] || []).length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Select
                  selectedOption={selectedRegion}
                  onChange={handleRegionChange}
                  options={regions.map(region => ({
                    label: region,
                    value: region
                  }))}
                  placeholder="Select AWS Region"
                  selectedAriaLabel="Selected"
                />
                <ButtonDropdown
                  onItemClick={handleDropdownClick}
                  items={[
                    {
                      text: "Modify",
                      id: "modify",
                      disabled: true,
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      disabled: selectedItems.length !== 1,
                    },
                    // {
                    //   text: "Run Stacker",
                    //   id: "run",
                    //   disabled: selectedItems.length === 0,
                    // },
                  ]}
                >
                  Actions
                </ButtonDropdown>

                <ConfirmationPopup
                  visible={isConfirmDeleteModalVisible}
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  title="Confirm Delete Stack?"
                  message="Are you sure you want to delete this stack? This cannot be undone!"
                />
              </SpaceBetween>
            }
          >
            Cloudformation Stacks
          </Header>
        }
        columnDefinitions={_columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={stackDataLoading || regionDataLoading}
        loadingText="Loading stacks"
        onSelectionChange={setSelectedStack}
        // trackBy="StackId"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter stacks"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
      />
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CloudformationStackList);
