
import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import reburaTheme from './reburaTheme'
import './index.css'
import { Provider } from "react-redux";
import { createStore } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "./oauth/reducers/_rootReducer";
import storage from "redux-persist/lib/storage";
import { createRoot } from 'react-dom/client';

import Router from "./routing/Router";

const theme: Theme = reburaTheme;
applyTheme({ theme });

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

const Root = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router />
        </PersistGate>
    </Provider>
);




const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);