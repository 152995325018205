import { connect } from "react-redux";
import {
	SpaceBetween
} from "@cloudscape-design/components";

import ReburaAppLayout from "../layout/ReburaAppLayout";
import CustomerList from "../components/customers/CustomerList";

const Customers = () => {

	return (
		<ReburaAppLayout page_title=''>
			<SpaceBetween size='l'>
				<CustomerList title='All Rebura Customers' />
			</SpaceBetween>
		</ReburaAppLayout>
	);
};

export default connect()(Customers);
