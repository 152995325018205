import {
    Header,
    Button,
    SpaceBetween,
    ButtonDropdown,
    Icon
 } from "@cloudscape-design/components";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const CustomerListTableHeader = ({ token, selectedItems, items }) => {

    const handleDropdownClick = async (e) => {
      e.preventDefault();

      try {
        switch (e.detail.id) {
          case 'run':
            await fetch(`${config.api_endpoint}/stacker/run`, {
              mode: "cors",
              method: "POST",
              headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`,
              },
              body: JSON.stringify({ customers: selectedItems }),
            });
            break;
          case 'modify':
            navigate(`/customers/${selectedItems[0]['customer_code']}/modify`);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const navigate = useNavigate();

    return (
        <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${items.length})`
                : `(${items.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={handleDropdownClick}
                  items={[
                    {
                      text: "Modify",
                      id: "modify",
                      disabled: selectedItems.length !== 1,
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      disabled: selectedItems.length !== 1,
                    },
                    {
                      text: "Run Stacker",
                      id: "run",
                      disabled: selectedItems.length === 0,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>

                <Button
                  variant="primary"
                  onClick={() => navigate("/customers/add")}
                >
                  Add new customer <Icon name='add-plus' />
                </Button>
              </SpaceBetween>
            }
          >
            Customers
          </Header>

    );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomerListTableHeader);
