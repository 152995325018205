import { useState } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Link,
    Icon,
    SpaceBetween,
    TextContent
 } from "@cloudscape-design/components";


import ValueWithLabel from "../common/ValueWithLabel";

const ParametersList = ({ parameters, template_name }) => {

    const [visible, setVisible] = useState(false);

    return (
        <>

         <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={"Parameters for " + template_name}
        >

            <SpaceBetween size='s'>
                <TextContent>
                    <p>This template has the following parameters set:</p>
                </TextContent>
                {parameters.map((parameter, key) => (
                    <>

                    <ValueWithLabel key={key} label={parameter['name']}>
                        Type: {parameter['type']}
                        <br />
                        Default Value: {parameter['value'] || "No Default Value"}
                        <br />
                        Valid Values: {parameter['valid_values']}
                    </ValueWithLabel>
                    <hr />
                    </>
                ))}
            </SpaceBetween>

        </Modal>

        <Link
            onFollow={() => {
                setVisible(true);
            }}
        >
            View ({parameters.length}) <Icon name='search' />
        </Link>
        </>

    );
};


export default connect()(ParametersList);
