import * as React from 'react';
import {
  StatusIndicator
} from '@cloudscape-design/components';

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export function getStackStatus(status, message_override=null) {


  let indicator_type = ""
  let colorOverride = ""

  switch (status) {

    case "DELETE_COMPLETE":
      indicator_type = "success"
      colorOverride = "red"
      break

    case "CREATE_COMPLETE":
    case "UPDATE_COMPLETE":
      indicator_type = "success"
      break

    case 'UPDATE_ROLLBACK_COMPLETE':
    case 'ROLLBACK_COMPLETE':
    case 'UPDATE_REQUIRED':
      indicator_type = 'warning'
      break

    case 'CREATE_FAILED':
    case 'DELETE_FAILED':
    case 'ROLLBACK_FAILED':
    case 'UPDATE_ROLLBACK_FAILED':
      indicator_type = "error"
      break

    case 'CREATE_IN_PROGRESS':
    case 'DELETE_IN_PROGRESS':
    case 'REVIEW_IN_PROGRESS':
    case 'ROLLBACK_IN_PROGRESS':
    case 'UPDATE_IN_PROGRESS':
    case 'UPDATE_ROLLBACK_IN_PROGRESS':
    case 'UPDATE_COMPLETE_CLEANUP_IN_PROGRESS':
    case 'UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS':
      indicator_type = "info"
      break

    default:
      indicator_type = "pending"
  }

  return (
    <StatusIndicator colorOverride={colorOverride} type={indicator_type}>
      {message_override ? message_override : status}
    </StatusIndicator>
  )
}

export const columnDefinitions = [
  {
    id: 'Timestamp',
    header: 'Timestamp',
    cell: item => item.timestamp,
    ariaLabel: createLabelFunction('Timestamp'),
    sortingField: 'Timestamp',
    isRowHeader: true,
  },
  {
    id: 'LogicalId',
    header: 'Logical ID',
    cell: item => item.logical_id,
    ariaLabel: createLabelFunction('Logical ID'),
    sortingField: 'LogicalId',
  },
  {
    id: 'Status',
    header: 'Status',
    cell: item => getStackStatus(item.status),
    ariaLabel: createLabelFunction('Status'),
    sortingField: 'status',
  },
  {
    id: 'DetailedStatus',
    header: 'Detailed Status',
    cell: item => item.detailed_status,
    ariaLabel: createLabelFunction('Detailed Status'),
    sortingField: 'DetailedStatus',
  },
  {
    id: 'StatusReason',
    header: 'Status Reason',
    cell: item => item.status_reason,
    ariaLabel: createLabelFunction('Status Reason'),
    sortingField: 'StatusReason',
  }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 events' },
    { value: 20, label: '20 events' },
    { value: 50, label: '50 events' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'StackName' })),
};

export const collectionPreferencesProps = {
    pageSizePreference,
    contentDisplayPreference,
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
};