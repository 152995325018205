import React, { createContext, useState, useContext, useMemo } from "react";

// Create a context for the split panel
const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
    const [navOpen, setNavOpen] = useState([]);
    const [splitPanelOpen, setSplitPanelOpen] = useState(true);
    const [splitPanel, setSplitPanel] = useState(null);
    const [flashMessages, setFlashMessages] = useState([]);

    const value = useMemo(() => ({
        navOpen,
        setNavOpen,
        splitPanelOpen,
        setSplitPanelOpen,
        splitPanel,
        setSplitPanel,
        flashMessages,
        setFlashMessages,
    }), [flashMessages, splitPanel, navOpen, splitPanelOpen]);



    return (
        <LayoutContext.Provider
            value={value}
        >
            {children}
        </LayoutContext.Provider>
    );
};

// Custom hook to use LayoutContext
export const useLayout = () => {
    const context = useContext(LayoutContext);
    if (!context) {
        throw new Error('useLayout must be used within a LayoutProvider');
    }
    return context;
};
