
import {
    Alert
 } from "@cloudscape-design/components";


const TemplatesHelpPanel = () => {

    return (
        <Alert statusIconAriaLabel="Info" header="What are Templates?">
        Templates are an interface between real CloudFormation templates from the rebura-cfn-templates repository. You must define templates here before you can deploy them.
        <br />
        <br />
        When creating a template, you can declare any Parameters you have in the CloudFormation template, as well as setting default values for them. Don't worry, default values can always be overridden.
      </Alert>

    );
};


export default TemplatesHelpPanel;
