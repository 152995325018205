const reburaTheme = {
  tokens: {
    // Border Radius
    borderRadiusButton: '10px',
    borderRadiusContainer: '5px',
    // Colors
    colorBackgroundLayoutMain: {
      light: '#ffffff',
      dark: '#1a1a1a'
    },
    colorTextBodyDefault: {
      light: '#2e3c42',
      dark: '#e9ebed'
    },
    colorTextAccent: {
      light: '#0288d1',
      dark: '#babfc7'
    },
    // Add more global token overrides as needed
  },
  contexts: {
    'top-navigation': {
      tokens: {
        // colorBackground: {
        //   light: '#ff3b30',
        //   dark: '#b71c1c'
        // },
        // colorText: {
        //   light: '#ffffff',
        //   dark: '#ffffff'
        // },
        // Add more top-navigation specific token overrides as needed
      }
    },
    'header': {
      tokens: {
        // colorBackground: {
        //   light: '#d32f2f',
        //   dark: '#b71c1c'
        // },
        // colorText: {
        //   light: '#ffffff',
        //   dark: '#ffffff'
        // },
        // Add more header specific token overrides as needed
      }
    },
    // Define tokens for other contexts like 'flashbar' and 'alert' as needed
  }
};

export default reburaTheme;
